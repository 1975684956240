import ContactForm from "../Components/ContactForm";
import MobilePhone from "../Components/mobile"

const Contact = () => {
  return (
    <div>
      <ContactForm />
      <MobilePhone/>
    </div>
  );
};

export default Contact;
